import React, { useEffect, useState } from "react";
import "./index.scss";
import InputComponent from "../../../components/common/Input/index";
import BlueDot from "../../../components/common/BlueDot/index";
import CustomRadioButton from "../../../components/common/CustomRadioButton/index";
import CustomButton from "../../../components/common/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import nextIcon from "../../../assets/images/next.svg";
import saveletter from "../../../assets/images/saveDraft.svg";
import { useHistory } from "react-router-dom";
import loadingIcon from "../../../assets/images/blue-Loading-spinner.gif";
import disableNextIcon from "../../../assets/images/DisabledNext.svg";
import { fetchLocally } from "../../../utils/localStorage";
import CustomModal from "../../../components/NewWorklogComponents/Modals/index";
import backIcon from "../../../assets/images/Back.svg";
import propTypes from 'prop-types';
import { student_path } from "../../../Constants/paths/student";
import { decodeOnBehalfToken } from "../../../utils/helper/common/decodeJSONToken";
import { resetOnBehalfSignIn } from "../../../store/actions/staff/onBehalfSignIn.action";
import { removeFromLocalStorage } from "../../../utils/localStorage";
import { staff_path } from "../../../Constants/paths/staff";

function NewWLStep1(props) {
  // this comment for disable useeffect dependency
  /* eslint-disable */
  const { workPlace, constructed, errorMessage, data, constructedSelectedValue, setConstructedSelectedValue,  loadingWorklogDetail, setinputChecked } = props;

  const dispatch = useDispatch();
    //fetching the data of worklog creation status
    const worklogCreationReducer = useSelector((state) => state?.worklog?.createWorklogsWorklogsReducer)
    const createWorklogStatus = worklogCreationReducer?.worklogCreated
  
  const [show, setShow] = useState(false);
  const history = useHistory();

  //these function will who and close the popup screen
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };
  const handleGoback=()=>{
    history.goBack()
  }

  //fetching the name of user from the localStorage
  const UserName = fetchLocally("user_first_name");

  //this effect will set the value of constructed details
  useEffect(() => {
    setConstructedSelectedValue({
      id: 0,
    });
    if ( constructed?.value || "") {
      const constructedValue = constructed?.value;

      if (constructedValue === "Family Home") {
        setConstructedSelectedValue({
          id: 1,
        });
      } else if (constructedValue === "Home Renovation/Extension") {
        setConstructedSelectedValue({
          id: 2,
        });
      } else {
        setConstructedSelectedValue({
          id: 3,
        });
      }
    }

  }, [constructed?.value, data?.constructionDetails]);

  //this effect will redirect the user tho the Worklog Page
  useEffect(() => {
    if (createWorklogStatus === true) {
      history.push(student_path.VIEW_WORKLOG_PATH);
    }
  }, [createWorklogStatus]);

  //these function will enable and disable the buttons
  const handleDisable = () => {
    if (
      String(constructed.value).trim() === "" || constructed.value === undefined ||
      String(workPlace.value).trim() === "" || workPlace.value === undefined
    ) {
      return true;
    } else {
      return false;
    }
  };

  //these function will handle the color of the buttons which is disbale or enable
  const handleColor = () => {
    if (
      String(constructed.value).trim() === "" ||
      String(workPlace.value).trim() === ""
    ) {
      return "disabled";
    } else {
      return "blue";
    }
  };

  //handlign the signout of onbehalf login
const handleOnbehalfLogOut =() =>{
  dispatch(resetOnBehalfSignIn.success());
  removeFromLocalStorage.setOnBehalfToken("onbehalf_token");
  history.push(staff_path.STAFF_DASHBOARD_PATH);
}

  return (loadingWorklogDetail === true || worklogCreationReducer.loadingWorklogs===true)? (
    <div className="loader">
      <img src={loadingIcon} alt="loading icon"></img>
    </div>
  ) : (
    <div>
      <div className={`newContainer`} style={{ paddingTop: "7em" }}>
        <h5 style={{ fontWeight: "bolder" }}>
          Hey {UserName}, what have you been up to lately?
        </h5>

        {/* Where Have u been Working */}
        <div>
          <div
            className={`d-flex align-items-center`}
            style={{ marginTop: "1.5em" }}
          >
            <BlueDot />
            <p
              className={`removeExtraBotMargin`}
              style={{ fontWeight: "bolder" }}
            >
              Where have you been working?
            </p>
          </div>

          <InputComponent
            className="customInputComponent"
            type="text"
            style={{ marginTop: "1em" }}
            placeholder="Jobsite Name or Street Address"
            // value={workPlace.value}
            value={workPlace.value}
            // value={step1data.WorkingDetails}
            //  onChange={e=>setStep1Data({
            //    WorkingDetails:e.target.value
            //  })}
            onChange={workPlace.onChange}
            // errorMessage={errorMessage.workPlace}
          />
        </div>

        {/* What was being constructed */}

        <div>
          <div
            className={`d-flex align-items-center`}
            style={{ marginTop: "1.5em", marginBottom: "1.5em" }}
          >
            <BlueDot />
            <p
              className={`removeExtraBotMargin`}
              style={{ fontWeight: "bolder" }}
            >
              What was being constructed?
            </p>
          </div>

          {/* //Family Home Home Renovation/Extension Other */}
          <CustomRadioButton
            id="Family Home"
            name="const"
            value="Family Home"
            label="Family Home"
            isInline={false}
            errorMessage={errorMessage.constructed}
            clearInput={constructed.clearInput}
            setInputValue={constructed.setInputValue}
            //value={constructed.value}
            onChange={constructed.onChange}
            checked={
              1 === constructedSelectedValue.id //constructedval === "Family Home"? constructedval : ""
            }
            setinputChecked={setinputChecked}
          />
          <CustomRadioButton
            id="Home Renovation/Extension"
            name="const"
            value="Home Renovation/Extension"
            label="Home Renovation/Extension"
            isInline={false}
            clearInput={constructed.clearInput}
            setInputValue={constructed.setInputValue}
            //value={constructed.value}
            onChange={constructed.onChange}
            errorMessage={errorMessage.constructed}
            checked={2 === constructedSelectedValue.id}
            setinputChecked={setinputChecked}
          />
          <CustomRadioButton
            id="Other"
            name="const"
            errorMessage={errorMessage.constructed}
            label="Other"
            isInline={false}
            otherInputBox={true}
            clearInput={constructed.clearInput}
            setInputValue={constructed.setInputValue}
            value={3 === constructedSelectedValue.id ? constructed.value === " " ? "" : constructed.value  : ""}
            onChange={constructed.onChange}
            checked={3 === constructedSelectedValue.id }
            setinputChecked={setinputChecked}
          />
          {/* <p>{errorMessage.constructed}</p> */}
          {/* <Form.Control.Feedback type="invalid">
                {errorMessage.constructed}
              </Form.Control.Feedback> */}
        </div>
      </div>
      <div
        className={decodeOnBehalfToken() ? `d-flex justify-content-center align-items-center fixedBottomContainer newContainer OnBehalfButtonPadding`:  `d-flex justify-content-center align-items-center fixedBottomContainer newContainer ButtonPadding`}
      >
       {decodeOnBehalfToken() && <div className={"cancel"} onClick={handleOnbehalfLogOut}>Close</div>} 
         <CustomButton
            image={backIcon}
            text="Back"
            type="transparent"
            width="50%"
            margin="0px 5px 0px 0px"
            onClick={handleGoback}
          />
        <CustomButton
          text="Save as Draft"
          width="50%"
          image={saveletter}
          disableimg={saveletter}
          margin="0px 5px 0px 0px"
          onClick={() => {
            handleShow();
          }}
        />

        <CustomModal
          modalFor="saveLater"
          show={show}
          setShow={setShow}
          handleClose={handleClose}
          handleShow={handleShow}
          handleErase={props.saveLater}
        />

        <CustomButton
          //disabled={(workPlace.value !== "" &&  constructed.value !== "")  ? false : true }
          disabled={handleDisable()}
          text="Next"
          //type={(workPlace.value !== "" &&  constructed.value !== "")  ? "blue" : "disabled" }
          type={handleColor()}
          width="50%"
          image={nextIcon}
          disableimg={disableNextIcon}
          //   margin="0px 5px 0px 0px"
          onClick={() => {
            //dispatch(step1Worklogs.request("23"));

            props.setStep({
              num: 2,
              text: "Tasks",
            });
          }}
        />
      </div>
    </div>
  );
}



NewWLStep1.propTypes = {
  loadingWorklogDetail: propTypes.bool,
  constructed: propTypes.object,
  errorMessage: propTypes.object,
  setStep: propTypes.func,
  workPlace: propTypes.object,
  data: propTypes.object,
  constructedSelectedValue: propTypes.object,
  setConstructedSelectedValue: propTypes.func,
  saveLater: propTypes.func,
  user: propTypes.any,
  inputChecked: propTypes.any,
  setinputChecked: propTypes.func,
};


export default NewWLStep1;

