import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import CustomButton from "../../../components/common/CustomButton";
import BlueDot from "../../../components/common/BlueDot/index";
import mainStyles from "../../../pages/NewWorklogPages/CreateWorkLog/NewWorkLog/styles.module.scss";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import saveletter from "../../../assets/images/saveDraft.svg";
import submit from "../../../assets/images/Submit.svg";
import loadingIcon from "../../../assets/images/blue-Loading-spinner.gif";
import CustomModal from "../../../components/NewWorklogComponents/Modals/index";
import { student_path } from "../../../Constants/paths/student";
import propTypes from "prop-types";
import ViewImageModal from "../ViewImageModal/ViewImageModal";
import backIcon from "../../../assets/images/Back.svg";
import { decodeOnBehalfToken } from "../../../utils/helper/common/decodeJSONToken";
import { resetOnBehalfSignIn } from "../../../store/actions/staff/onBehalfSignIn.action";
import { removeFromLocalStorage } from "../../../utils/localStorage";
import { useDispatch } from "react-redux";
import { staff_path } from "../../../Constants/paths/staff";

function NewWLStep5(props) {
  // this comment for disable useeffect dependency
  /* eslint-disable */

  const dispatch = useDispatch();
  const fetchWorkLogSupervisorData = useSelector(
    (state) => state.worklog.fetchWorklogSupervisorReducer
  );

  const fetchWorkLogTasksData = useSelector(
    (state) => state.worklog.fetchWorklogTasksReducer
  );

  //fetching the data of worklog creation status
  const worklogCreationReducer = useSelector(
    (state) => state?.worklog?.createWorklogsWorklogsReducer
  );
  const createWorklogStatus = worklogCreationReducer?.worklogCreated;

  const [submitDisable, setSubmitDisable] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const supervisorArray = fetchWorkLogSupervisorData.supervisors;
  const {
    submitForm,
    data,
    workPlace,
    constructed,
    task,
    photoHappening,
    moreEquipments,
    photoPart,
    supervisor,
    howLong,
    photoUpload,
    otherComment,
    overallComment,
    mergedPhotoArray,
    mergedEquipments,
    setUpload,
    showUploadImg,
    currentEquipments,
  } = props;
  const selectedEquipments = currentEquipments.filter((item) => {
    if (mergedEquipments.includes(item.equipment_id.toString())) {
      return item;
    }
  });
  const equipArrayString = selectedEquipments
    .map((item) => item.name)
    .concat(moreEquipments.value?.filter((item) => item !== ""));
  // const fetchWorkLogEquipmentData = useSelector(
  //   (state) => state.worklog.fetchWorklogEquipmentReducer
  // );

  const history = useHistory();
  const supervisorID = supervisor.value;

  // const supervisorIDR =
  //   data?.taskDetail[0]?.photos != undefined &&
  //   data?.taskDetail[0]?.photos[0]?.supervisor_id != undefined
  //     ? data?.taskDetail[0]?.photos[0]?.supervisor_id
  //     : "";

  // finalEquip.splice((),1)
  const checkPhotoVal =
    data == undefined
      ? photoUpload?.value
      : photoUpload?.value == ""
      ? props?.data?.taskDetail[0]?.photos?.length > 0
        ? "true"
        : "false"
      : photoUpload?.value;

  // const worklogEquip =
  //   data?.worklog?.task != undefined ? data?.worklog?.task[0].equipments : [];
  // const modifiedWorklogEquip =
  //   worklogEquip != undefined &&
  //   worklogEquip.map((item) => {
  //     return {
  //       equipment_id: item.id,
  //       name: item.name,
  //     };
  //   });

  // const mergeArrayOfObjects = (original, newdata, selector = "key") => {
  //   newdata.forEach((dat) => {
  //     const foundIndex =
  //       original.length > 0 &&
  //       original.findIndex((ori) => ori[selector] == dat[selector]);
  //     if (foundIndex >= 0 && original.length > 0)
  //       original.splice(foundIndex, 1, dat);
  //     else original.length > 0 && original.push(dat);
  //   });

  //   return original;
  // };

  // const FilteredEquipArray = mergeArrayOfObjects(
  //   modifiedWorklogEquip,
  //   fetchWorkLogEquipmentData?.equipments,
  //   "equipment_id"
  // );
  // const EquipNameArray = FilteredEquipArray.map(item=> item.name)

  useEffect(() => {
    setUpload(
      checkPhotoVal == "true" ? true : checkPhotoVal == "false" ? false : ""
    );
  }, []);

  useEffect(() => {
    if (createWorklogStatus == true) {
      history.push(student_path.VIEW_WORKLOG_PATH);
    }
  }, [createWorklogStatus]);

  // const prefinalEquip = mergedEquipments?.map(
  //   (equipment) =>
  //     fetchWorkLogEquipmentData?.equipments.find(
  //       (e) => e.equipment_id == equipment
  //     )?.name
  // );

  // const finalEquip = prefinalEquip?.filter(
  //   (item) => item != undefined && item != ", " && item != ""
  // );

  // const filteredAndReArranged =
  //   FilteredEquipArray != false &&
  //   mergedEquipments?.map(
  //     (equipment) =>
  //       FilteredEquipArray.find((e) => e.equipment_id == equipment)?.name
  //   );
  const moreEquipFinalArray = moreEquipments.value.filter(
    (item) => item !== ""
  );

  //this function will open the modal which will handle the popup of the image
  const [viewImage, setViewImage] = useState(false);
  const [imgData, setImgData] = useState("");
  const handleViewImage = (img) => {
    setViewImage(true);
    setImgData(img);
  };

  //handle go back button
  const handleGoback = () => {
    // history.goBack()
  };

    //handlign the signout of onbehalf login
const handleOnbehalfLogOut =() =>{
  dispatch(resetOnBehalfSignIn.success());
  removeFromLocalStorage.setOnBehalfToken("onbehalf_token");
  history.push(staff_path.STAFF_DASHBOARD_PATH);
}
  return (
    <div>
      {(submitDisable == true ||
        worklogCreationReducer.loadingWorklogs === true) && (
        <div className={styles.loader}>
          <img src={loadingIcon} alt="loading icon"></img>
        </div>
      )}
      <div
        className={`newContainer ${mainStyles.addExtraPaddingtop}`}
        style={{ paddingTop: "4em" }}
      >
        {/* Details Div step 1*/}
        <div className="mb-4">
          <p className="removeExtraBotMargin mb-3">
            <span className={`${styles.header1}`}>Details -</span>{" "}
            <span className={`${styles.header2}`}>Step 1/4</span>
          </p>
          {/* Where have you being working Container */}
          <div className="mb-4">
            <div className="d-flex align-items-baseline mb-1">
              <BlueDot />
              <p className="removeExtraBotMargin heading">
                Where have you been working?
              </p>
            </div>
            <p
              className={`removeExtraBotMargin ${styles.shiftTextRightAndStyle}`}
            >
              {/* {data?.workingDetails || workPlace?.value} */}
              {workPlace?.value}
            </p>
          </div>

          {/* What was being constructed Container */}
          <div className="mb-4">
            <div className="d-flex align-items-baseline mb-1">
              <BlueDot />
              <p className="removeExtraBotMargin heading">
                What was being constructed?
              </p>
            </div>
            <p
              className={`removeExtraBotMargin ${styles.shiftTextRightAndStyle}`}
            >
              {/* {data?.constructionDetails || constructed?.value} */}
              {constructed?.value}
            </p>
          </div>
        </div>

        {/* Tasks Div step 2*/}
        <div className="mb-4">
          <p className="removeExtraBotMargin mb-3">
            <span className={`${styles.header1}`}>Tasks -</span>
            <span className={`${styles.header2}`}>Step 2/4</span>
          </p>
          {/* Overdue Tasks Container */}
          {task?.value?.name ? (
            <div className="mb-4">
              <div className="d-flex align-items-baseline mb-1">
                <p
                  className={`removeExtraBotMargin ${styles.shiftTextRightAndStyle}`}
                >
                  {task?.value?.name}
                </p>
              </div>
            </div>
          ) : (
            <div className="mb-4">
              <div className="d-flex align-items-baseline mb-1">
                <p
                  className={`removeExtraBotMargin ${styles.shiftTextRightAndStyle}`}
                >
                  {
                    fetchWorkLogTasksData.tasks.filter(
                      (item) => item.task_id == data.taskDetail[0].task_id
                    )[0]?.name
                  }
                </p>
              </div>
            </div>
          )}
        </div>

        {/* photo Tasks Container  step-3*/}
        <div className="mb-4">
          <p className="removeExtraBotMargin mb-3">
            <span className={`${styles.header1}`}>Photo -</span>{" "}
            <span className={`${styles.header2}`}>Step 3/4</span>
          </p>
          {/* {photos.map(Img =>(
            <>
            <div className="thumbnailImage"><img src={Img} alt="thumbnails" /></div>
            </>
          ))} */}
          <div className="mb-4">
            <div className="d-flex align-items-baseline mb-1">
              <BlueDot />
              <p className="removeExtraBotMargin heading">
                {`Have you got a photo of the ${
                  task?.value == "" ? "task name" : task?.value?.name
                }?`}
              </p>
            </div>
            <p
              className={`removeExtraBotMargin ${styles.shiftTextRightAndStyle}`}
            >
              {/* {photoUpload?.value == "true" ? "Yes" : dataTasklength ? "Yes" : "No"} */}
              {showUploadImg ? "Yes" : "No"}
            </p>
          </div>
          {showUploadImg ? (
            <>
              {/* If the photo answer is YES than show this div */}
              <div className="mb-4">
                {mergedPhotoArray.map((Img) => (
                  <>
                    <div
                      className="thumbnailImage"
                      onClick={() => handleViewImage(Img)}
                    >
                      <img src={Img} alt="thumbnails" />
                    </div>
                  </>
                ))}
              </div>
              <ViewImageModal
                show={viewImage}
                setShow={setViewImage}
                values={imgData}
                setValue={setImgData}
              />
              <div className="mb-4">
                <div className="d-flex align-items-baseline mb-1">
                  <BlueDot />
                  <p className="removeExtraBotMargin heading">
                    What's happening in this photo?
                  </p>
                </div>
                <p
                  className={`removeExtraBotMargin ${styles.shiftTextRightAndStyle}`}
                >
                  {photoHappening?.value}
                </p>
              </div>
              <div className="mb-4">
                <div className="d-flex align-items-baseline mb-1">
                  <BlueDot />
                  <p className="removeExtraBotMargin heading">
                    How long did it take?
                  </p>
                </div>
                <p
                  className={`removeExtraBotMargin ${styles.shiftTextRightAndStyle}`}
                >
                  {howLong?.value}
                </p>
              </div>
              {/* Equipment Tools Container */}
              <div className="mb-4">
                <div className="d-flex align-items-baseline mb-1">
                  <BlueDot />
                  <p className="removeExtraBotMargin heading">Equipment</p>
                </div>
                <p
                  className={`removeExtraBotMargin ${styles.shiftTextRightAndStyle}`}
                >
                  {equipArrayString.length > 0 &&
                    equipArrayString.map((equip, index) => (
                      <span key={index}>
                        {equip}
                        {index < equipArrayString.length - 1 ? ", " : ""}
                      </span>
                    ))}
                  {/* {moreEquipFinalArray.length > 0 &&
                  (finalEquip.length > 0 || filteredAndReArranged.length > 0)
                    ? ", "
                    : ""}
                  {moreEquipFinalArray.map((item, idx) => (
                    <>
                      {item}
                      {idx < moreEquipFinalArray.length - 1 ? ", " : ""}{" "}
                    </>
                  ))} */}
                </p>
              </div>

              <div className="mb-4">
                <div className="d-flex align-items-baseline mb-1">
                  <BlueDot />
                  <p className="removeExtraBotMargin heading">
                    Which supervisor can verify your activity and photo?
                  </p>
                </div>
                <p
                  className={`removeExtraBotMargin ${styles.shiftTextRightAndStyle}`}
                >
                  {supervisorID?.toString()?.includes('temp') ? (
                    <>
                      {supervisorArray
                        .filter(
                          (array) =>
                            array.temp_emp_id ==
                            supervisorID.replace("temp", "")
                        )
                        .map((supervisorName) => (
                          <>
                            {supervisorName.first_name}{" "}
                            {supervisorName.last_name}
                          </>
                        ))}
                    </>
                  ) : (
                    <>
                      {supervisorArray
                        .filter((array) => array.contact_id == supervisorID)
                        .map((supervisorName) => (
                          <>
                            {supervisorName.first_name}{" "}
                            {supervisorName.last_name}
                          </>
                        ))}
                    </>
                  )}
                </p>
              </div>
            </>
          ) : (
            <>
              {/* If the photo answer is NO than show this div */}
              <div className="mb-4">
                <div className="d-flex align-items-baseline mb-1">
                  <BlueDot />
                  <p className="removeExtraBotMargin heading">
                    What was your part in the activity?
                  </p>
                </div>
                <p
                  className={`removeExtraBotMargin ${styles.shiftTextRightAndStyle}`}
                >
                  {photoPart?.value}
                </p>
              </div>
              <div className="mb-4">
                <div className="d-flex align-items-baseline mb-1">
                  <BlueDot />
                  <p className="removeExtraBotMargin heading">
                    How long did it take?
                  </p>
                </div>
                <p
                  className={`removeExtraBotMargin ${styles.shiftTextRightAndStyle}`}
                >
                  {howLong?.value}
                </p>
              </div>
              {/* Equipment Tools Container */}
              <div className="mb-4">
                <div className="d-flex align-items-baseline mb-1">
                  <BlueDot />
                  <p className="removeExtraBotMargin heading">Equipment</p>
                </div>
                <p
                  className={`removeExtraBotMargin ${styles.shiftTextRightAndStyle}`}
                >
                  {equipArrayString.length > 0 &&
                    equipArrayString.map((equip, index) => (
                      <span key={index}>
                        {equip}
                        {index < equipArrayString.length - 1 ? ", " : ""}
                      </span>
                    ))}
                </p>
              </div>
            </>
          )}
        </div>

        {/* Comments Div step-4*/}
        <div className="mb-4">
          <p className="removeExtraBotMargin mb-3">
            <span className={`${styles.header1}`}>Comments -</span>{" "}
            <span className={`${styles.header2}`}>Step 4/4</span>
          </p>
          {/* Other Comments Container */}
          <div className="mb-4">
            <div className="d-flex align-items-baseline mb-1">
              <BlueDot />
              <p className="removeExtraBotMargin heading">
                Any other comments you want to say about your activity?
              </p>
            </div>
            <p
              className={`removeExtraBotMargin ${styles.shiftTextRightAndStyle}`}
            >
              {otherComment?.value}
            </p>
          </div>

          {/* Overall Comments Container */}
          <div className="mb-4">
            <div className="d-flex align-items-baseline mb-1">
              <BlueDot />
              <p className="removeExtraBotMargin heading">
                Any other overall comments?
              </p>
            </div>
            <p
              className={`removeExtraBotMargin ${styles.shiftTextRightAndStyle}`}
            >
              {overallComment?.value}
            </p>
          </div>
        </div>
      </div>

      <div
        className={
          decodeOnBehalfToken()
            ? `d-flex justify-content-center align-items-center fixedBottomContainer newContainer OnBehalfButtonPadding`
            : `d-flex justify-content-center align-items-center fixedBottomContainer newContainer ButtonPadding`
        }
      >
        {decodeOnBehalfToken() && (
          <div className={"cancel"} onClick={handleOnbehalfLogOut}>
            Close
          </div>
        )}
        <CustomButton
          image={backIcon}
          text="Back"
          type="transparent"
          width="50%"
          margin="0px 5px 0px 0px"
          onClick={() =>
            props.setStep({
              num: 4,
              text: "Details",
            })
          }
        />
        <CustomButton
          text="Save as Draft"

          width="50%"
          image={saveletter}
          disableimg={saveletter}
          margin="0px 5px 0px 0px"
          onClick={() => {
            handleShow();
          }}
        />
        <CustomModal
          modalFor="saveLater"
          show={show}
          setShow={setShow}
          handleClose={handleClose}
          handleShow={handleShow}
          handleErase={props.saveLater}
        />

        <CustomButton
          text={"Submit"}
          image={submit}
          disableimg={submit}
          width="50%"
          onClick={() => {
            submitForm();
            setSubmitDisable(true);
          }}
        />
      </div>
    </div>
  );
}

NewWLStep5.propTypes = {
  workPlace: propTypes.object,
  constructed: propTypes.object,
  errorMessage: propTypes.object,
  setStep: propTypes.func,
  data: propTypes.object,
  fetchWorkLogEquipmentData: propTypes.array,
  task: propTypes.object,
  current: propTypes.object,
  equipments: propTypes.object,
  moreEquipments: propTypes.object,
  photoUpload: propTypes.object,
  photoHappening: propTypes.object,
  photoPart: propTypes.object,
  howLong: propTypes.object,
  supervisor: propTypes.object,
  otherComment: propTypes.object,
  overallComment: propTypes.object,
  executeScroll: propTypes.func,
  navImage: propTypes.func,
  submitForm: propTypes.func,
  saveLater: propTypes.func,
  files: propTypes.array,
  photos: propTypes.array,
  mergedPhotoArray: propTypes.array,
  mergedEquipment: propTypes.array,
  setMergedEquipments: propTypes.func,
  showUploadImg: propTypes.bool,
  setUpload: propTypes.func,
};

export default NewWLStep5;
